<template>
  <div>
    <button id="speech" :class="['btn', { active: isRecording, pink: isNotMuted }]" @click="toggleRecording">
      <i :class="iconClass"></i>
      <div :class="['pulse-ring', { active: isRecording, pink: isNotMuted }]"></div>
    </button>
  <audio id="audioPlayer" ref="audioPlayer" style="display: none;" ></audio>
</div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'VoiceAssistant',
  data() {
    return {
      recognition: null,
      transcript: '',
      recognizing: false,
      sendTimeout: null,
      audioElement: null,
      handsfree: null,
      handsDetected: false,
      newMessage: this.$store.state.audio,
      audioQueue: [],
      chunkBuffer: '',
      currentSourceIndex: 0,
      playing: false,
      pauseTimer: null,
      messageSpoken: false,
      isRecording: false,
      audioData: null,
      mediaRecorder: null,
      audioChunks: [],
      mediaSource: null,
      sourceBuffer: null,
      isOn: false,
    };
  },
  props: {
    playStream: {
      type: Boolean,
      default: false,
    },
    isTyping: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    audioText() {
      return this.$store.state.audio;
    },
    iconClass() {
      if (this.isRecording) {
        return 'bx bx-stop';
      }
      return this.isOn ? 'bx bxs-microphone' : 'bx bxs-microphone-off';
    },
    isNotMuted() {
      return this.isOn; // Check if the microphone is not muted
    },
  },
  watch: {
    audioText(newVal) {
      this.newMessage = newVal;
    },
    playStream(newVal) {
      if (!newVal) {
        this.stopAudio(); // Stop or mute the audio when playStream is false
      } else {
        this.playTTS(this.newMessage);
      }
      console.log(newVal);
      console.log(this.message);
    },
  },
  methods: {
    toggleRecording() {
      this.setupKeyEvents();
      this.isOn = !this.isOn;
    },
    setupKeyEvents() {
      window.addEventListener('keydown', this.handleKeyDown);
      window.addEventListener('keyup', this.handleKeyUp);
    },
    async handleKeyDown(event) {
      if ((event.key === 'T' || event.key === 't') && !this.isRecording && this.isOn) {
        this.startRecording();
      }
    },
    handleKeyUp(event) {
      if ((event.key === 'T' || event.key === 't') && this.isRecording && this.isOn) {
        this.stopRecording();
      }
    },
    async startRecording() {
      if (this.isTyping) {
        console.log('Typing in progress. Recorder will not activate.');
        return; // Exit the function early if typing is true
      }
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.addEventListener('dataavailable', (event) => {
          if (event.data.size > 0) {
            this.audioChunks.push(event.data);
          }
        });
        this.mediaRecorder.addEventListener('stop', () => {
          this.audioData = new Blob(this.audioChunks, { type: 'audio/wav' });
          this.audioUrl = URL.createObjectURL(this.audioData);
          this.audioChunks = [];
        });
        this.mediaRecorder.start();
        this.isRecording = true;
        document.querySelector('.pulse-ring').classList.add('active');
      } catch (error) {
        console.error('Error accessing microphone:', error);
      }
    },
    stopRecording() {
      if (this.mediaRecorder && this.isRecording) {
        this.mediaRecorder.stop();
        this.isRecording = false;
        setTimeout(() => {
          this.sendRecording();
        }, 1000);
        document.querySelector('.pulse-ring').classList.remove('active');
      }
    },
    async sendRecording() {
      if (this.audioData) {
        console.log('Sending audio to dispatcher');
        // this.$store.dispatch('sendAudio', this.audioData);
        const formData = new FormData();
        formData.append('audio', this.audioData, 'audio.wav');
        const response = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/eddie/voice`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log('response data', response);
        this.$store.dispatch('TranscriptText', response.data.text);
        // Reset recording state
        this.audioData = null;
        this.audioUrl = null;
      }
    },
    async streamTTS(text, voice = 'alloy', onEnd = () => {}) {
      try {
        const serverUri = process.env.VUE_APP_AXIOS_URI;
        const url = `${serverUri}/game-story/tts-stream`;

        console.log('Fetching TTS stream from:', url);
        const token = localStorage.getItem('userToken');
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ input: text, voice }),
        });

        console.log('Response received:', response);

        if (!response.body) {
          throw new Error('ReadableStream not supported by the response');
        }

        this.mediaSource = new MediaSource();
        const { audioPlayer } = this.$refs;

        if (audioPlayer) {
          audioPlayer.src = URL.createObjectURL(this.mediaSource);

          this.mediaSource.addEventListener('sourceopen', () => {
            console.log('MediaSource opened');
            this.sourceBuffer = this.mediaSource.addSourceBuffer('audio/mpeg');

            const reader = response.body.getReader();

            const appendBuffer = (value) => new Promise((resolve, reject) => {
              this.sourceBuffer.addEventListener('updateend', () => { resolve(); }, { once: true });
              this.sourceBuffer.addEventListener('error', () => reject(new Error('SourceBuffer error')), { once: true });
              this.sourceBuffer.appendBuffer(value);
            });

            const pump = async () => {
              try {
                // eslint-disable-next-line
                while (true) { // eslint-disable-next-line
                  const { done, value } = await reader.read();
                  if (done) {
                    this.mediaSource.endOfStream();
                    break;
                  } // eslint-disable-next-line
                  await appendBuffer(value);
                }
              } catch (error) {
                this.mediaSource.endOfStream();
              }
            };

            pump().then(() => {
              if (audioPlayer.paused) {
                audioPlayer.play().catch((error) => {
                  console.error('Error playing audio:', error);
                });
              }
            });
            audioPlayer.onended = () => {
              console.log('Audio playback ended');
              this.$emit('tts-ended');
              onEnd();
            };
          });
        }
      } catch (error) {
        console.error('Error streaming TTS:', error);
      }
    },

    playTTS(text) {
      if (this.playStream) {
        document.querySelector('.pulse-ring').classList.remove('active');
        this.streamTTS(text);
      } else {
        console.log('playStream is false, TTS will not be played.');
      }
    },
    stopAudio() {
      const { audioPlayer } = this.$refs;
      if (audioPlayer) {
        audioPlayer.pause(); // Stop the playback
        audioPlayer.currentTime = 0; // Reset the playback to the beginning (optional)
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('keyup', this.handleKeyUp);
  },
};
</script>

<style scoped>
#speech.btn {
  border: none;
  padding: 0;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  font-size: 1.5em;
  color: #fff;
  background: #a5a5a5;
  position: relative;
  display: inline-block;
  line-height: 10px;
  text-align: center;
  cursor: pointer;
}
#speech.btn.pink.active {
  background: var(--accentRed);
}
#speech.btn.pink {
  background: #fd9abb;
}

.pulse-ring.pink {
  border: 3px solid #fd9abb;
}

.pulse-ring {
  width: 30px;
  height: 30px;
  border: 3px solid #a5a5a5;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  left: -5px;
  animation: none;
}

.pulse-ring.active {
  border: 3px solid var(--accentRed);
  animation: pulsate infinite 1s;
}

@keyframes pulsate {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

.bx {
  font-size: 10px;
}
</style>
