<!-- eslint-disable vue/valid-v-for -->
<template>
  <div class="chat-container">
    <div>
      <div class="chat-messages" ref="chatMessages">
        <p class="file-count">Attached Files: {{ filesCount }}</p>
        <div class="chat-messages__header">
          <button
            v-if="!isLoadingPreviousChat"
            class="chat-messages__load-more ed-btn__blim"
            @click="loadPreviousMessages"
            :disabled="isLoadingPreviousChat"
          >
            <i class="bx bx-chevron-up box-icon box-icon--disabled"></i>
            <span>Load Previous Messages</span>
          </button>
          <span v-else class="spinner-border text-secondary"></span>
        </div>
        <a-list item-layout="horizontal" :data-source="messages">
          <template #renderItem="{ content, role, id }">
            <div v-for="singleMessage in content" :key="singleMessage">
              <li v-for="(singleMessage, index) in content" :key="index">
                <div v-if="singleMessage.type === 'text'" class="text-message">
                  <div class="message-header">
                    <img
                      class="tj-icon"
                      v-if="role === 'assistant'"
                      src="../../../assets/images/tj-logo.png"
                    />
                    <i
                      class="bx bx-user-circle user-profile"
                      v-if="role === 'user'"
                    ></i>
                    <div :class="getMessageClass(role)">
                      <!-- Render content dynamically -->
                      <div v-html="formattedContent(singleMessage.text)"></div>
                      <div
                        v-if="
                          showSourcesList &&
                          sourceMessageID == id &&
                          role === 'assistant'
                        "
                      >
                        <ul>
                          <li
                            v-for="(link, index) in limitedLinks"
                            :key="index"
                          >
                            <a :href="link" target="_blank">{{ link }}</a>
                          </li>
                        </ul>
                      </div>
                      <button
                        class="ed-btn ed-btn__blim execute-btn"
                        v-if="hasCodeBlock(singleMessage.text.value)"
                        @click="executeCode(singleMessage.text.value, id)"
                      >
                        Execute
                      </button>
                      <div
                        class="suggestions-container"
                        v-if="
                          showSuggestions &&
                          playstreamMessageId == id &&
                          role === 'user'
                        "
                      >
                        <button
                          v-for="(suggestion, index) in suggestions"
                          :key="index"
                          class="suggestion-button"
                          @click="applySuggestion(suggestion)"
                        >
                          {{ suggestion }}
                        </button>
                      </div>
                      <div class="icon-buttons" v-if="role === 'assistant'">
                        <i class='bx bxs-show' @click="visualizeResponse(singleMessage.text.value)"></i>
                        <i
                          :class="[
                            'bx',
                            playStream && this.playstreamMessageId === id
                              ? 'bx-volume-full'
                              : 'bx-volume-mute',
                            'play-stream',
                          ]"
                          @click="
                            playStreamClicked(singleMessage.text.value, id)
                          "
                          v-if="role === 'assistant'"
                        ></i>
                        <i
                          class="bx bx-copy"
                          @click="copyMessage(singleMessage.text.value)"
                        ></i>
                        <i
                          class="bx bx-link-external"
                          @click="toggleSources(id)"
                        ></i>
                      </div>
                      <div class="icon-buttons" v-if="role === 'user'">
                        <i
                          class="bx bx-refresh"
                          @click="refreshResponse(id)"
                        ></i>
                        <i
                          class="bx bx-help-circle help"
                          @click="toggleSuggestions(id)"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="singleMessage.type === 'image_url'"
                  class="image-message"
                >
                  <img :src="singleMessage.image_url.url" style="width: 100%" />
                </div>
                <div v-else>Not a valid content</div>
              </li>
            </div>
          </template>
        </a-list>
      </div>
      <div class="input-container">
        <div class="input-container__tools">
          <VoiceAssistant
            class="input-container__tool input-container__tool--recorder"
            :isTyping="isTyping"
            :playStream="playStream"
            @tts-ended="handleTTSEnded"
          />
          <span v-if="this.imageGenerationLoader" class="spinner-border text-primary input-container__tool" style="margin: 0 1rem"></span>
          <span v-else class="input-container__tool input-container__tool--image" @click="toggleImageGeneration" title="Generate Image">
            <i class="bx bxs-torch"></i>
          </span>
          <span class="input-container__tool" @click="toggleDynamicInput" title="Set Text">
            <i class="bx bx-cog"></i>
          </span>
        </div>
        <div v-if="showDynamicInput" class="dynamic-input-box">
          <input
            v-model="dynamicPropKeys"
            type="text"
            placeholder="Set a value"
            class="dynamic-input"
          />
          <button @click="applyDynamicValue" class="apply-button">
            Apply
          </button>
        </div>
        <div class="box-container">
          <div v-if="uploadingFile" class="loader-overlay">
            <div class="loader"></div>
          </div>
          <div class="preview-box">
            <div
              v-for="(item, index) in imagePreviews"
              :key="index"
              class="preview-item"
            >
              <div v-if="item.type === 'image'" class="image-preview">
                <img :src="item.url" />
                <i class="bx bx-x-circle" @click="removeImage(index)"></i>
              </div>
              <div v-else class="file-preview">
                <i id="attachment" class="bx bx-paperclip bx-rotate-270"></i>
                <span>{{ item.fileName }}</span>
                <i class="bx bx-x-circle" @click="removeImage(index)"></i>
              </div>
            </div>
          </div>
          <div class="chat-input">
            <div class="upload-menu">
              <i
                class="bx bx-paperclip bx-rotate-270"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              ></i>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" href="#">
                    <label for="file-upload"> Upload File </label>
                    <input
                      id="file-upload"
                      type="file"
                      @change="handleFileUpload"
                      multiple
                    />
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    <label for="folder-upload"> Upload Folder </label>
                    <input
                      id="folder-upload"
                      type="file"
                      @change="handleFileUpload"
                      multiple
                      webkitdirectory
                    />
                  </a>
                </li>
                <li><hr class="dropdown-divider" /></li>
                <li>
                  <a class="dropdown-item" href="#">
                    <label for="file-upload"> Upload Image </label>
                    <input
                      id="file-upload"
                      type="file"
                      @change="handleFileUpload"
                      multiple
                    />
                  </a>
                </li>
              </ul>
            </div>
            <textarea
              v-model="newMessage"
              @keydown="handleKeyDown"
              @input="adjustHeight"
              placeholder="Message Assistant"
              ref="textarea"
              class="chat-textarea"
            />
            <button class="send-button" @click="sendMessage">
              <i class="bx bx-up-arrow-alt"></i>
            </button>
          </div>
        </div>
      </div>
      </div>
    </div>
  <!-- </div> -->
</template>

<script>
import marked from 'marked';
import axios from 'axios';
import Papa from 'papaparse';
import graphReactor from '@/common/core/Graph/graphReactor';
import VoiceAssistant from '../../components/VoiceAssistant.vue';

export default {
  data() {
    return {
      newMessage: this.$store.state.transcription,
      graphId: '',
      imageFile: null,
      messages: [],
      imagePreviews: [],
      uploadingFile: false,
      isTyping: false,
      pendingTTS: '',
      isLoadingPreviousChat: false,
      isFocused: false,
      isStreaming: false,
      copiedMessageIndex: null,
      lastQuery: '',
      playStream: false,
      latestAssistantMessage: '',
      streamMessage: '',
      suggestions: [],
      showSuggestions: false,
      sources: '',
      showSourcesList: false,
      filesCount: null,
      playstreamMessageId: null,
      sourceMessageID: null,
      imageGenerationLoader: false,
      showDynamicInput: false,
      dynamicPropKeys: '',
    };
  },
  components: {
    VoiceAssistant,
  },
  props: {
    assistantId: String,
    threadId: String,
    vectorStoreId: String,
    initialQuestion: String,
  },
  async mounted() {
    this.fetchThreadMessages();
    // this.adjustHeight();
    this.scrollToBottom();
    window.addEventListener('paste', this.handlePaste);
    this.$refs.textarea.addEventListener('focus', this.handleFocus);
    this.$refs.textarea.addEventListener('blur', this.handleBlur);
    if (this.initialQuestion) {
      this.newMessage = this.initialQuestion;
      this.sendMessage();
    }
  },
  computed: {
    transcriped() {
      return this.$store.state.transcription;
    },
    limitedLinks() {
      return this.sources.slice(0, 3); // Return only the first 3 links
    },
  },
  watch: {
    threadId() {
      console.log('thread changed');
      console.log('Vector Store ID:', this.vectorStoreId);
      this.fetchVectorStoreFiles(this.vectorStoreId);
      // Vector Store ID: vs_CwxlHEiSK6MQOLpSUeP8uUWB
      this.fetchThreadMessages();
    },
    transcriped(newVal) {
      this.newMessage = newVal;
      this.sendMessage();
    },
    playstreamMessageId(newValue) {
      console.log('playstreamMessageId updated:', newValue);
    },
  },

  methods: {
    toggleDynamicInput() {
      this.showDynamicInput = !this.showDynamicInput;
    },
    applyDynamicValue() {
      if (this.dynamicPropKeys.trim()) {
        this.showDynamicInput = false;
      }
    },
    async fetchVectorStoreFiles(vectorStoreId) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_AXIOS_URI}/eddie/vector-store/files-count`,
          {
            id: vectorStoreId,
          },
        );
        this.filesCount = response.data.fileCount;
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    },
    async fetchSuggestions(text) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_AXIOS_URI}/eddie/prompt-suggestions`,
          {
            prompt: text,
          },
        );

        this.suggestions = response.data.contents.suggestions || [];
        console.log(this.suggestions);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    },
    applySuggestion(suggestion) {
      this.newMessage = suggestion;
      this.sendMessage();
      this.showSuggestions = false;
    },
    toggleSuggestions(id) {
      this.showSuggestions = !this.showSuggestions;
      if (this.showSuggestions) {
        this.playstreamMessageId = id;
        const userMessage = this.messages.find((message) => message.id === id);
        this.fetchSuggestions(userMessage.content[0].text.value);
      } else {
        this.playstreamMessageId = null;
      }
    },
    toggleSources(id) {
      this.showSourcesList = !this.showSourcesList;
      if (this.showSourcesList) {
        this.sourceMessageID = id;
      } else {
        this.sourceMessageID = null;
      }
    },
    handleFocus() {
      this.isFocused = true;
    },
    handleBlur() {
      this.isFocused = false;
    },
    formattedContent(content) {
      const { value, annotations } = content;
      let sanitizedContent = value;
      if (annotations) {
        if (annotations.length) {
          annotations.forEach((ann) => {
            // eslint-disable-next-line camelcase
            const { file_citation, text } = ann;
            sanitizedContent = sanitizedContent.replaceAll(
              text,
              `<a href="/file/${file_citation.file_id}" target="_blank">${text}</a>`,
            );
          });
        }
      }
      return marked(sanitizedContent);
    },
    generateRandomNumber() {
      const min = 1; // Set the minimum value of the range
      const max = 100; // Set the maximum value of the range
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    async fetchThreadMessages() {
      const threadMessages = await this.$store.dispatch(
        'eddie/fetchMessagesUsingThreadId',
        { threadId: this.threadId },
      );
      const charts = await this.$store.dispatch('eddie/fetchSmartCharts', { threadId: this.threadId });
      const tempMessages = [];
      threadMessages.forEach((message) => {
        const chart = charts.find((each) => each.message_id === message.id);
        if (chart) {
          const newContentArr = {
            assistantId: null,
            attachments: null,
            content: [
              {
                type: 'image_url',
                image_url: {
                  annotations: [],
                  url: chart.chart_url,
                },
              },
            ],
          };
          tempMessages.push(newContentArr);
          tempMessages.push(message);
        } else {
          tempMessages.push(message);
        }
      });
      this.messages = tempMessages.reverse();
      this.scrollToBottom();
    },
    async loadPreviousMessages() {
      this.isLoadingPreviousChat = true;
      const threadMessages = await this.$store.dispatch(
        'eddie/fetchMessagesUsingThreadId',
        {
          threadId: this.threadId,
          after: this.messages[0].id,
        },
      );
      this.messages = [...threadMessages.reverse(), ...this.messages];
      this.isLoadingPreviousChat = false;
      // this.messages = threadMessages.reverse();
    },
    hasCodeBlock(content) {
      const codeBlockRegex = /```[\s\S]*?```/g;
      return codeBlockRegex.test(content);
    },
    extractCodeBlock(content) {
      const codeBlockRegex = /```(?:\w+)?\s*([\s\S]*?)```/;
      const match = content.match(codeBlockRegex);
      return match ? match[1].trim() : '';
    },
    async executeCode(content, id) {
      const code = this.extractCodeBlock(content);
      console.log('Executing code:', code);
      console.log(id);

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_ANALYTICS_URL}/run-script/`,
          { code },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
        const newContentArr = {
          assistantId: null,
          attachments: null,
          content: [
            {
              image_url: {
                annotations: [],
                url: `${
                  process.env.VUE_APP_ANALYTICS_URL
                }/static/generated_images/${response.data.output.trim()}`,
              },
              type: 'image_url',
            },
          ],
        };
        const imageUrl = `${process.env.VUE_APP_ANALYTICS_URL}/static/generated_images/${response.data.output.trim()}`;
        const messageId = id;
        this.messages.push(newContentArr);
        // You can handle the response and display the result in the UI as needed
        await this.sendMessageIdAndImageUrl(messageId, imageUrl, this.threadId);
      } catch (error) {
        console.error('xvf', 'Error executing code:', error);
        // Handle the error as needed, possibly displaying a message in the UI
      }
    },
    async sendMessageIdAndImageUrl(messageId, imageUrl, threadId) {
      try {
        const response = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/eddie/update-chartSchema`, {
          messageId,
          imageUrl,
          threadId,
        });
        console.log('Data sent to backend successfully:', response.data);
      } catch (error) {
        console.error('Error sending data to backend:', error);
      }
    },
    async makeEddieSearchUsingAssistantAndThread() {
      try {
        this.isStreaming = true;
        const tempQuery = this.newMessage;
        this.newMessage = '';
        this.messages.push({
          content: [
            {
              type: 'text',
              text: {
                value: '',
                annotations: [],
              },
            },
          ],
          role: 'assistant',
        });
        const imageInput = this.imageFile && this.imageFile.type.match(/^image\/(png|jpeg|gif)$/)
          ? this.imageFile
          : null;
        await this.$store.dispatch(
          'eddie/streamResponseWithAssistantAndThreadId',
          {
            userPrompt: tempQuery,
            imageInput,
            threadId: this.threadId,
            assistantId: this.assistantId,
            callback: async (chunk) => {
              this.scrollToBottom();
              console.log(this.messages[this.messages.length - 1]);
              if (chunk.trim().startsWith('msg_ended')) {
                const msgObjString = chunk.replace('msg_ended', '').trim();
                const msgObjParse = JSON.parse(msgObjString);
                this.messages[this.messages.length - 1] = msgObjParse;
                // eslint-disable-next-line no-unused-vars
                const temp = [...this.messages];
                // this.messages = [];
                setTimeout(() => {
                  this.messages = [...temp];
                  this.scrollToBottom();
                }, 200);
              } else {
                this.messages[this.messages.length - 1].content[0].text.value += chunk;
              }
            },
          },
        );
        // this.sendToAssistant(assistantResponse);
        this.isStreaming = false;
        // const assistantResponse = this.messages[this.messages.length - 1].content[0].text.value;
        // try {
        //   const response = await axios.post(
        //     `${process.env.VUE_APP_AXIOS_URI}/eddie/google-search`,
        //     {
        //       query: assistantResponse,
        //     },
        //     {
        //       headers: {
        //         'Content-Type': 'application/json',
        //       },
        //     },
        //   );
        //   console.log('Source:', response.data);
        //   this.sources = response.data.map((result) => result.link);
        // } catch (error) {
        //   console.error(
        //     'Error:',
        //     error.response ? error.response.data : error.message,
        //   );
        // }
      } catch (error) {
        console.error('Error:', error);
        this.messages.push({
          content: [
            {
              type: 'text',
              text: {
                value:
                  'Something went wrong while processing your request. Please try again.',
              },
            },
          ],
          role: 'assistant',
        });
      }
    },
    async handleFileUpload(event) {
      const { files } = event.target;

      // Ensure the file is not undefined
      if (!files.length) {
        console.error('No file uploaded');
        return;
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        console.log(file, 'uploaded');

        if (file.type.match(/^image\/(png|jpeg|gif)$/)) {
          this.imageFile = file;
          const imageUrl = URL.createObjectURL(file);
          this.imagePreviews.push({ type: 'image', url: imageUrl });
          console.log('This file is an image. Proceed with the operations.');
        } else if (file.type.match(/^text\/csv$/)) {
          try {
            Papa.parse(file, {
              header: true,
              complete: (results) => {
                const dataInJSON = results.data;

                // Create a JSON file from the JSON data
                const jsonBlob = new Blob([JSON.stringify(dataInJSON)], {
                  type: 'application/json',
                });
                const jsonFile = new File(
                  [jsonBlob],
                  file.name.replace('.csv', '.json'),
                  { type: 'application/json' },
                );
                this.uploadingFile = true;
                this.imageFile = jsonFile;
                this.imagePreviews.push({ type: 'file', fileName: file.name });
                this.updateMemoryOfThread(jsonFile).then(() => {
                  this.imageFile = null;
                });
              },
              error: (error) => {
                console.error('Error processing CSV file:', error);
              },
            });
          } catch (error) {
            console.error('Error processing CSV file:', error);
          }
        } else {
          this.uploadingFile = true;
          this.imageFile = file;
          this.imagePreviews.push({ type: 'file', fileName: file.name });
          this.updateMemoryOfThread(file);
          this.imageFile = null;
        }
      }
      // only if its not jpg/png etc
    },
    convertCsvToJson(csv) {
      const lines = csv.split('\n');
      const headers = lines[0].split(',');
      return lines.slice(1).map((line) => {
        const values = line.split(',');
        return headers.reduce((acc, header, index) => {
          acc[header] = values[index];
          return acc;
        }, {});
      });
    },
    async updateMemoryOfThread(file) {
      if (!this.vectorStoreId) return;

      const formData = new FormData();
      formData.append('vectorStoreId', this.vectorStoreId);
      formData.append('doc', file);

      try {
        const response = await fetch(
          `${process.env.VUE_APP_AXIOS_URI}/eddie/smart-vector-update`,
          {
            method: 'POST',
            headers: {
              // No Content-Type header needed for FormData
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            },
            body: formData,
          },
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(
            `HTTP error! Status: ${response.status}, Message: ${errorData.message}`,
          );
        }

        const data = await response.json();
        console.log('Success:', data);
        this.fetchVectorStoreFiles(this.vectorStoreId);
        const eventPayload = {
          verb: 'FILE_UPLOADED_TO_VECTOR_STORE',
        };
        this.$store.dispatch('actionFeedback', eventPayload);
        this.uploadingFile = false;
        this.imageFile = null;
        this.imagePreviews = [];
      } catch (err) {
        console.error('Error:', err.message);
      }
    },
    async sendMessage() {
      if (this.newMessage.trim() === '') return;
      const content = [];
      this.lastQuery = this.newMessage;

      content.push({
        type: 'text',
        text: {
          value: this.newMessage,
        },
      });

      if (
        this.imageFile
        && this.imageFile.type.match(/^image\/(png|jpeg|gif)$/)
      ) {
        content.push({
          type: 'image_url',
          image_url: {
            url: URL.createObjectURL(this.imageFile),
            detail: 'auto',
          },
        });
      }

      this.messages.push({
        role: 'user',
        content,
      });

      //   this.newMessage = '';
      this.imagePreviews = [];
      this.scrollToBottom();
      await this.makeEddieSearchUsingAssistantAndThread();
      this.adjustHeight();
      // await this.$store.dispatch('eddie/fetchMessagesUsingThreadId');
      this.imageFile = null;
      // await this.$store.dispatch(
      //   'eddie/fetchMessagesUsingThreadId',
      //   { threadId: this.threadId },
      // );
    },
    removeImage(index) {
      this.imagePreviews.splice(index, 1);
    },
    getMessageClass(type) {
      return {
        'user-message': type === 'user',
        'assistant-message': type === 'assistant',
      };
    },
    handleKeyDown(event) {
      if (event.key === 'Enter' && event.shiftKey) {
        return;
      }
      if (event.key === 'Enter') {
        event.preventDefault();
        this.sendMessage();
      }
    },
    scrollToBottom() {
      const chatMessages = document.querySelector('.chat-messages');
      chatMessages.scrollTo({
        top: chatMessages.scrollHeight,
      });
    },
    adjustHeight() {
      this.isTyping = this.newMessage.length > 0;
      const { textarea } = this.$refs;
      textarea.style.height = '25px';
      textarea.style.height = `${textarea.scrollHeight}px`;
    },
    handlePaste(event) {
      if (!this.isFocused) {
        return;
      }

      event.preventDefault();

      const clipboardData = event.clipboardData || event.originalEvent.clipboardData;
      const htmlData = clipboardData.getData('text/html');
      const plainText = clipboardData.getData('text/plain');

      if (!this.newMessage) {
        this.newMessage = '';
      }

      if (htmlData) {
        this.parseHtmlAndExtractImages(htmlData);
      } else if (plainText) {
        this.newMessage += plainText;
      }
      this.$nextTick(() => {
        this.adjustHeight();
      });
    },
    parseHtmlAndExtractImages(html) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const imageElements = Array.from(doc.images);
      imageElements.forEach((img) => {
        fetch(img.src)
          .then((response) => response.blob())
          .then((blob) => {
            const imageUrl = URL.createObjectURL(blob);
            const file = new File([blob], 'image.png', { type: blob.type });
            this.imagePreviews.push({ type: 'image', url: imageUrl });
            this.imageFile = file;
          });
      });
      if (!this.newMessage) {
        this.newMessage = '';
      }
      const text = doc.body.textContent || '';
      this.newMessage += text.trim();
    },
    copyMessage(text) {
      const eventPayload = {
        verb: 'COPY_TO_CLIPBOARD',
      };
      this.$store.dispatch('actionFeedback', eventPayload);
      navigator.clipboard
        .writeText(text)
        .then(() => {
          console.log('Message copied to clipboard!');
          // You can add a notification or visual feedback here if needed
        })
        .catch((err) => {
          console.error('Failed to copy message: ', err);
        });
    },
    async refreshResponse(id) {
      const messageID = id;
      const userMessage = this.messages.find(
        (message) => message.id === messageID,
      );
      this.newMessage = userMessage.content[0].text.value;
      await this.makeEddieSearchUsingAssistantAndThread();
    },
    isLastMessage(singleMessage) {
      const lastMessage = this.messages[this.messages.length - 1];
      return (
        lastMessage
        && lastMessage.content
        && lastMessage.content.includes(singleMessage)
      );
    },
    playStreamClicked(text, id) {
      this.playStream = !this.playStream;
      this.playstreamMessageId = id;
      this.streamMessage = text;
      if (this.playStream) {
        this.$store.dispatch('audioText', this.streamMessage);
        this.streamMessage = '';
      }
    },
    handleTTSEnded() {
      this.playStream = false; // Mute the icon when TTS ends
      this.playstreamMessageId = null;
    },
    async toggleImageGeneration() {
      this.messages.push({
        content: [
          {
            type: 'text',
            text: {
              value: this.newMessage,
            },
          },
        ],
        role: 'user',
      });
      console.log(this.messages);
      try {
        this.imageGenerationLoader = true;
        const payload = {
          query: this.newMessage,
          threadId: this.threadId,
        };
        this.newMessage = '';
        const response = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/eddie/smart-image`, payload);
        this.messages.push({
          content: [
            {
              type: 'image_url',
              image_url: {
                url: response.data.chart_url,
                annotations: [],
              },
            },
          ],
          role: 'assistant',
        });
        this.imageGenerationLoader = false;
        this.scrollToBottom();
      } catch (error) {
        this.imageGenerationLoader = false;
      }
    },
    async visualizeResponse(text) {
      console.log(text);
      // eslint-disable-next-line no-underscore-dangle
      const id = this.$store.state.active_graph_id.explore_library;
      console.log(id);
      const payload = {
        payload_text: text,
        graphId: id,
      };
      const { nodes } = await this.$store.dispatch('gmodule/createDataForSmartgrraph', payload);
      console.log('nodes', nodes);
      if (nodes.length > 0) {
        if (this.dynamicPropKeys.trim() !== '') {
          console.log('has properties');
          this.$store.dispatch('gmodule/groupedPropertiesWithOpenAI', {
            selectedNodes: nodes.map((node) => graphReactor.exploreInstance.findById(node.id)),
            properties: this.dynamicPropKeys.trim(),
          });
        } else {
          console.log('no properties');
        }
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('paste', this.handlePaste);
  },
};
</script>

<style lang="scss" scoped>
.dynamic-input-box {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
}

.dynamic-input {
  flex: 1;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.apply-button {
  padding: 5px 10px;
  background-color: #0964b0;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.apply-button:hover {
  background-color: #084d8c;
}

.icon {
  transform: scale(2);
  margin: 10px;
}
.tj-icon {
  width: 35px;
  height: 35px;
  margin-top: 20px;
}
input[type="file"] {
  display: none;
}
.icon:hover {
  cursor: pointer;
}
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.animated-dot {
  height: 10px;
  width: 10px;
  background-color: #010148;
  border-radius: 50%;
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}
.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  max-height: 400px;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__load-more {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.loader-overlay {
  margin-left: 10px;
  margin-top: 10px;
}
.preview-item {
  padding: 10px;
  position: relative;
}
.image-preview .bx {
  position: absolute;
  top: 3px;
}
.user-message img {
  width: 90px;
  padding: 5px;
  border-radius: 10px;
  background-color: none;
}

.user-message {
  background-color: #f7f7f7;
  border-radius: 5px;
  width: 100%;
  padding: 15px;
  margin: 10px;
  margin-bottom: 20px;
  float: left;
  text-align: left;
  align-items: center;
}
.user-message p {
  margin: 0px;
}
.assistant-message {
  background-color: #fefdf5;
  border-radius: 5px;
  width: 100%;
  padding: 15px;
  margin: 10px;
  margin-bottom: 20px;
  float: right;
  text-align: left;
  align-items: center;
}
.assistant-message p {
  margin: 0px;
}
.chat-textarea {
  font-size: 14px;
  box-sizing: border-box;
  border: none;
  outline: none;
  resize: none;
  overflow: hidden;
  width: 100%;
  height: 25px;
  min-height: 25px;
  margin-left: 5px;
}
.chat-textarea:focus {
  outline: none;
  box-shadow: none;
}
#attachment {
  color: black;
  font-size: 13px;
  margin-left: 5px;
}
.chat-input {
  display: flex;
  align-items: center;
  padding: 8px;
}
.input-container {
  display: flex;
  align-items: center;

  &__tools {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  &__tool {
    cursor: pointer;
    position: relative;
    /* recorder */
    &--recorder {
      margin-left: .5rem;
    }

    /* image-generation */
    &--image{
      font-size: 1rem;
      background: var(--color-grey-1);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      aspect-ratio: 1;
      padding: .3rem;
      margin: 0 1rem;

      &::before {
        border: 2px solid var(--color-grey-1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(1.4);
      }

      i {
        font-size: 1.1rem;
        color: #fff;
      }
    }

    &::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 50%;
      left: 50%;
      /* transform: translate(-50%, -50%); */
    }
  }
}
.preview-box img {
  width: 40px;
  border-radius: 5px;
  margin-left: 25px;
}
.preview-box .bx {
  font-size: 13px;
  cursor: pointer;
  color: red;
}
.chat-input label {
  font-size: 10px;
  margin-top: 3px;
  border-radius: 30px;
}
.message-header {
  display: flex;
  align-items: flex-start;
}
.chat-input label .bx {
  font-size: 20px;
  cursor: pointer;
  font-weight: 500;
  color: var(--color-grey-1);
}
.icon-buttons {
  display: flex;
  gap: 15px;
  margin-top: 20px;
  cursor: pointer;
  margin-left: auto;
  justify-content: flex-end;
}
.box-container {
  display: block;
  width: 100%;
  border: 1px solid #f0f0f0;
  border-radius: 30px;
  /* margin-bottom: 10px; */
}

.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid var(--color-grey-1);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.send-button {
  margin-top: auto;
  padding: 4px;
  margin-bottom: auto;
  width: 25px;
  height: 25px;
  border: none;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  color: #0964b0;
  border-radius: 50%;
  cursor: pointer;
}
.send-button:hover {
  background-color: #f0f0f0;
}
.send-button .bx {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-grey-1);
}
.upload-menu {
  cursor: pointer;
  color: var(--color-grey-1);
  font-size: 15px;
}
.preview-box {
  display: flex;
  flex-wrap: wrap;
}
.execute-btn {
  margin-top: 10px;
}
.recorder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.play-stream {
  cursor: pointer;
}
.suggestion-button {
  border-radius: 20px;
  padding: 20px;
  background-color: #eeeef5;
  border: none;
  margin: 0.5rem;
}
.suggestion-container {
  display: flex;
  flex-direction: column;
}
.user-profile {
  font-size: 35px;
  color: #d0d0d0;
  margin-top: 20px;
  margin-right: 10px;
}
.file-count {
  text-align: right;
}
.image-btn {
  align-items: center;
  font-size: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 5px;
  margin-bottom: 10px;
}

.image-message {
  padding: 20px;
}

</style>
